<!-- <div class="container-fluid lgCard  mx-auto" *ngIf="cambioPass === false">
    <div class="card card0 border-0">
        <div class="row">
            <div class="col-lg-6">
                <div class="card1 pb-5">
                    <div class="text-center">
                        <img src="../../../assets/images/LOGO ONE Negro.png" class="logo">
                    </div>
                    <div class="row px-3 justify-content-center mt-4 mb-5 border-line">
                        <img src="https://i.imgur.com/uNGdWHi.png" class="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="card2 card border-0 px-4 py-5">
                    <div class="text-center">
                        <h1>Bienvenido</h1>
                    </div>
                    <div class="row px-3 mb-4">
                        <div class="line"></div> <small class="or text-center">Or</small>
                        <div class="line"></div>
                    </div>
                    <form [formGroup]="formLogin" (ngSubmit)="login()">
                        <mat-form-field appearance="outline" style="margin-top: 5%;">
                            <mat-label>Usuario</mat-label>
                            <input matInput placeholder="Correo" formControlName="usuario" required autocomplete="off">
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="margin-top: 5%;">
                            <mat-label>Contraseña</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Contraseña"
                                formControlName="clave" required autocomplete="off">
                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        </mat-form-field>
                        <div class="text-right cambioPass">
                            <a (click)="cambioPass = true">¿Has olvidado tu contraseña?</a>
                        </div>

                        <div class="text-center mt-4" *ngIf="!(authService.responseCargando$ | async)">
                            <button type="submit" class="buttonPrincipal text-center btnLogin"
                                mat-raised-button>Entrar</button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
        <div class="bg-blue py-4 text-center">
            <div class="row px-3"> <small class="ml-4 ml-sm-5 mb-2">Copyright &copy; 2021. GO504 Todos los derechos
                    reservados</small>
                <div class="social-contact ml-4 ml-sm-auto"> <span class="fa fa-facebook mr-4 text-sm"></span> <span
                        class="fa fa-google-plus mr-4 text-sm"></span> <span class="fa fa-linkedin mr-4 text-sm"></span>
                    <span class="fa fa-twitter mr-4 mr-sm-5 text-sm"></span>
                </div>
            </div>
        </div>
    </div>
</div>
 -->

<div style="display: flex;"  *ngIf="cambioPass === false">
    <div class="design" style="width: 73%;">
        <div
            class="relative hidden mdflex flex-auto items-center h-full justify-center p-16 lgpx-28 overflow-hidden bg-gray-800">
            <svg class="absolute inset-0 pointer-events-none" viewBox="0 0 960 540" width="100%" height="100%"
                preserveAspectRatio="xMidYMax slice" xmlns="http://www.w3.org/2000/svg">
                <g class="text-gray-700 opacity-25" fill="none" stroke="currentColor" stroke-width="100">
                    <circle r="234" cx="196" cy="23"></circle>
                    <circle r="234" cx="790" cy="491"></circle>
                </g>
            </svg>
            <svg class="absolute -top-16 -right-16 text-gray-700" viewBox="0 0 220 192" width="220" height="192"
                fill="none">
                <defs>
                    <pattern id="837c3e70-6c3a-44e6-8854-cc48c737b659" x="0" y="0" width="20" height="20"
                        patternUnits="userSpaceOnUse">
                        <rect x="0" y="0" width="4" height="4" fill="currentColor"></rect>
                    </pattern>
                </defs>
                <rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
            </svg>
            <div  >
                <div>  <img  src="./assets/images/LOGO ONE Blanco.png"  style=" height: 200px !important; width: 600px" class="logo"></div>
                
            </div>
         
        </div>
      
    </div> 
   
    <div class="formDesign" style="width: 27%; height: 100vh; display: flex; align-items: center; justify-content: flex-end;">
        <div id="cover">
            <div id="form-ui">
             
                <div class="d-xs-block d-sm-block  d-md-block d-lg-none d-xl-none ">
                    <img src="./assets/images/LOGO ONE Negro.png"  class="logo">
                </div>
            
                <hr>
                <form [formGroup]="formLogin" (ngSubmit)="login()">
                    <div id="form-body">
                        <div class="text-6xl font-bold leading-none text-blue">
                            <div> Iniciar sesión</div>
                        </div>
                        <!-- <div class="text-left cambioPass">
                            <a (click)="cambioPass = true">¿Has olvidado tu contraseña?</a>
                        </div> -->
                        <mat-form-field appearance="outline" style="margin-top: 35px;">
                            <mat-label>Usuario</mat-label>
                            <input matInput placeholder="Correo" formControlName="usuario" required autocomplete="off">
                            <mat-hint *ngIf="formLogin.get('usuario').invalid && formLogin.get('usuario').touched" style="color: red;">El correo electronico es requerido</mat-hint>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="margin-top: 35px;">
                            <mat-label>Contraseña</mat-label>
                            <input matInput [type]="hide ? 'password' : 'text'" placeholder="Contraseña"
                                formControlName="clave" required autocomplete="off">
                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                            <mat-hint *ngIf="formLogin.get('clave').invalid && formLogin.get('clave').touched" style="color: red;">La contraseña es requerida</mat-hint>
                        </mat-form-field>

                        <div class="mt-4 text-right cambioPass" style="color: #6366f1;">
                            <a (click)="cambioPass = true">¿Has olvidado tu contraseña?</a>
                        </div>

                        <div class="text-center" *ngIf="!(authService.responseCargando$ | async)" style="margin-top: 70px;">
                            <button type="submit" class="buttonSecundary text-center btnLogin" mat-raised-button >
                                Iniciar Sesión
                            </button>
                        </div>
                        

                        <div class="center" *ngIf="(authService.responseCargando$ | async).loading">
                            <mat-spinner></mat-spinner>
                        </div>

                        <div style="display: flex; justify-content: center; width: 100%;">
                            <div class="py-4 text-right" >
                                <div class="row text-right" style="white-space:initial"> 
                                    <small class="text-right">Copyright &copy; 2022. GO504 Todos los derechos reservados</small>
                                </div>
                            </div>
                        </div>

                    </div>
                   
                   
                </form>
               
               
       
            </div>
        
        </div>
       
    </div>
    
</div>

<div class="contenedorPadre" *ngIf="cambioPass === true">
    
    <mat-card class="container">
        <mat-card-content>
        
            <div class="forms-container">
                <div class="img-signup">
                    <img src="assets/images/undraw_authentication_fsn5.svg" class="img-fluid">
                </div>
                <div class="signin-signup">

                    <div class="sign-in-form">
                        <h2 class="title">Cambio Contraseña</h2>
                        <div class="row">
                            <mat-form-field appearance="legacy" class="col-md-12">
                                <mat-label>Correo</mat-label>
                                <input matInput type="email" placeholder="Coreo" [formControl]="correo"  required autocomplete="off" required>
                            </mat-form-field>
                        </div>

                        <div style="display: flex; justify-content: space-around; margin-top: 8%;">
                            <div class="text-right">
                                <button mat-raised-button class="buttonSecundary" (click)="cambioPass = false">
                                    Volver
                                </button>
                            </div>
                            <div class="text-left">
                                <button mat-raised-button class="buttonPrincipal" (click)="envioCambioPassword()">Siguiente</button>
                            </div>
                        </div>
                        
                    </div>
    
                </div>
            </div>
    
        </mat-card-content>
    </mat-card>
</div>